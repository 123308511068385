<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">网上展览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/list' }">展览管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{exhibitionTitle}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/content/' + this.$route.params.m_id }">内容设置</el-breadcrumb-item>
      <el-breadcrumb-item>滚动图管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="menu-area">
        <el-button type="primary" @click="toAdd">添加滚动图</el-button>
      </div>
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="type" label="链接类型" width="200">
          <template slot-scope="scope">
            <el-tag effect="plain" v-if="scope.row.type === 1">内部链接</el-tag>
            <el-tag type="success" effect="plain" v-if="scope.row.type === 2">外部链接</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="img" label="图片">
          <template slot-scope="scope">
            <el-image class="banner-img" fit="fill" :src="scope.row.img" :preview-src-list="[scope.row.img]" lazy></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="url" label="链接" width="200">
          <template slot-scope="scope">
            <el-tag effect="plain">{{scope.row.url}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="330">
          <template slot-scope="scope">
            <el-button size="small" type="success" @click="editItem(scope.row.m_id)">编辑</el-button>
            <el-button type="danger" size="small" @click="delItem(scope.row.m_id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      dataList: [],
      exhibitionTitle: ''
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      const { data: res } = await this.$http.get('/exhibition-banner-list', { params: { m_id: this.$route.params.m_id } })
      if (res.status === 200) {
        this.dataList = res.data.list
        this.exhibitionTitle = window.sessionStorage.getItem('ex_name')
      } else {
        this.$message.error(res.msg)
      }
    },
    editItem (mid) {
      this.$router.push('/admin/apps/exhibition/content/' + this.$route.params.m_id + '/banner/edit/' + mid)
    },
    async delItem (mid) {
      const { data: res } = await this.$http.delete('/exhibition-banner/', { params: { m_id: mid } })
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getData()
      } else {
        this.$message.error(res.msg)
      }
    },
    toAdd () {
      this.$router.push('/admin/apps/exhibition/content/' + this.$route.params.m_id + '/banner/add')
    }
  }
}
</script>

<style scoped>
.menu-area{
  padding: 0 0 20px 0;
  text-align: right;
}
.banner-img{
  max-width: 100px;
  max-height: 100px;
}
</style>
